@import './styles/abstracts';

@import './styles/components';
@import './styles/pages';

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  max-width: fit-content;
  display: block;
}

form.loginForm {
  width: 300px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.137);
  background: white;
  padding: 40px 20px;

  .loginForm__login,
  .loginForm__password {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 5px;
    }
    input {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      outline: none;
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.123);
      background: #fafbff;
      &:focus {
        box-shadow:
          inset 0 0 3px rgba(0, 0, 0, 0.123),
          0 0 2px blue;
      }
      & + span {
        color: red;
        font-size: 14px;
      }
    }
  }
  .loginForm__password {
    margin-top: 20px;
  }
  .loginForm__submit {
    margin-top: 35px;
    text-align: center;
    button {
      background: $primary-color;
      padding: 10px 20px;
      color: white;
      font-size: 18px;
      border-radius: 5px;
      text-transform: uppercase;
      outline: none;
      border: none;
      box-shadow: 4px 4px 5px hsla(0, 0%, 0%, 0.171);
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
